import React from 'react'
import "../../src/index.css"

const Contact = () => {
  return (
    <div name='contact' className='w-full min-h-screen  bg-[#0a192f] flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/feb3d0aa-1560-4555-8300-aa74aa3a9aef" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-4 pt-24'>
                <p className='text-4xl font-bold inline border-b-4 border-[#00ff00] text-gray-300'>Contact</p>
                <p className='text-gray-300 py-4'>// Submit the form below</p>
            </div>
            <input className='bg-[#ccd6f6] p-2 ' type="text" placeholder='Enter Your Name...' name='name' />
            <input className='my-4 p-2 bg-[#ccd6f6]  ' type="email" placeholder='Enter Your Email...' name='email' />
            <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-white border-2 hover:bg-[#23db23]  hover:border-[#23db23] font-semibold px-4 py-3 my-8 mx-auto flex items-center '>Let's Collaborate</button>
        </form>
    </div>
  )
}

export default Contact